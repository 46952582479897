import React, { Component } from 'react';
import captchaIcon from '../img/captcha-icon.png';

export class Captcha extends Component {
    titleRef = null;
    constructor(props) {
        super(props);
        this.state = {
            title: "Question",
            editTitle: false,
            isRequired: false,
            name: 'question',
            id: 'question',
            notice: '',
            errorMessage: 'This field is required.',
            isActive: false,
            displaytype: 'horizontal'
        }
        this.titleRef = React.createRef();
    };

    getContent = () => {
        const { id, name, title, isRequired, errorMessage, notice, displaytype } = this.state;
        return {
            id,
            name,
            title,
            isRequired,
            errorMessage,
            notice,
            displaytype,
            type: this.props.type
        };
    };

    onClickEditTitle = () => {
        this.setState({
            editTitle: true
        });
        setTimeout(() => {
            this.titleRef.current.focus();
        });
    };

    handleStateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
        this.setProperties({ [name]: value });
    };

    onFocusOutTitle = () => {
        this.setState({
            editTitle: false
        });
    };

    toggleIsRequired = (e) => {
        e.preventDefault();
        this.setState({
            isRequired: !this.state.isRequired
        });
        this.setProperties({ isRequired: !this.state.isRequired });
    };

    setProperties = (sendData) => {
        const { title, isRequired, name, notice, errorMessage, id, displaytype } = this.state;
        const { type } = this.props;
        const properties = {
            type,
            id: id,
            title: title,
            name: name,
            notice: notice,
            isRequired: isRequired,
            errorMessage: errorMessage,
            displaytype: displaytype,
            ...sendData
        };
        this.props.setPropertiesData(properties, this.props.location);
        this.setIsActive(true);
    }

    changeProperties = (formContent) => {
        const { title, name, notice, errorMessage, isRequired, id, displaytype } = formContent;
        this.setState({
            title: title,
            id: id,
            name: name,
            notice: notice,
            errorMessage: errorMessage,
            isRequired: isRequired,
            displaytype: displaytype,
        });
        this.props.setPropertiesData(formContent, this.props.location);
    };

    onClickDelete = () => {
        this.props.onClickDelete(this.props.location);
    };

    setIsActive = (isActive) => {
        this.setState({
            isActive
        });
    };

    render() {
        const { title, editTitle, isRequired, notice, errorMessage, isActive, displaytype } = this.state;
        return (
            <div className="col-4">
                <div className="form-group">
                    <label><strong>Captcha</strong><span className="red">*</span></label>
                    <textarea className="form-control" name="value" placeHolder=''></textarea>
                    <div className="form-row mt-2">
                        <div className="col-10">
                            <input type="text" name="value" className="form-control" placeHolder='' />
                        </div>
                        <div className="col-2">
                            <a href="#"><img src={captchaIcon} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}