import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WorkflowEditor } from "./WorkflowEditor";

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <WorkflowEditor />;
  }
}
export default App;
