import React, { Component } from 'react';
import './css/workflow.css';
import { HTMLProperties, HTMLDisplay, HTMLActions } from './components';
import { Scrollbars } from 'react-custom-scrollbars';
import { menuIcons } from './img';

export const componentType = {
    TEXT: "text",
    RADIO: "radio",
    CHECK_BOX: "checkbox",
    TEXT_AREA: "textarea",
    SELECTBOX: "select",
    FILE: "file",
    DATE: "date",
    LABEL: "label"
};

export class RightPartProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rightActiveTab: null,
        };
        this.htmlpropertiesRef = React.createRef();
        this.displaypropertiesRef = React.createRef();
        this.actionpropertiesRef = React.createRef();
    };

    setRightActiveTab = (rightActiveTab) => {
        this.setState({
            rightActiveTab
        });
    };

    setProperties = (properties) => {
        this.displaypropertiesRef.current.setProperties(properties);
        this.setState({ rightActiveTab: 0 });
    }

    onChangeProperties = (formContent) => {
        this.props.onChangeContent(formContent);
    };

    render() {
        const { showEditorPage, properties, rightActiveTab, formData } = this.state;
        return (
            <div className="right-side">
                <ul className="tabs">
                    <li className={rightActiveTab === 0 ? 'active' : ''} onClick={e => this.setRightActiveTab(0)}>Properties</li>
                    <li className={rightActiveTab === 1 ? 'active' : ''} onClick={e => this.setRightActiveTab(1)}>Display</li>
                    <li className={rightActiveTab === 2 ? 'active' : ''} onClick={e => this.setRightActiveTab(2)}>Actions</li>
                </ul>
                <div className={rightActiveTab === 0 ? "d-block tab-container properties-tab-container" : "d-none"}>
                    <Scrollbars
                        style={{ maxHeight: '100%' }}
                    >
                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            <HTMLProperties ref={this.htmlpropertiesRef} />
                        </div>
                    </Scrollbars>
                </div>
                <div className={rightActiveTab === 1 ? "d-block tab-container display-tab-container" : "d-none"} >
                    <Scrollbars
                        style={{ maxHeight: '100%' }}
                    >
                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            <HTMLDisplay ref={this.displaypropertiesRef} onChangeContent={this.onChangeProperties} />
                        </div>
                    </Scrollbars>
                </div>
                <div className={rightActiveTab === 2 ? "d-block tab-container actions-tab-container" : "d-none"}>
                    <Scrollbars
                        style={{ maxHeight: '100%' }}
                    >
                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            <HTMLActions ref={this.actionpropertiesRef} />
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}