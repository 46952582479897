import React, { Component } from 'react';
export class Table extends Component {
    titleRef = null;
    constructor(props) {
        super(props);
        this.state = {
            title: "Title",
            editTitle: false,
            name: 'question',
            id: 'question',
            isActive: false,
            displaytype: 'horizontal'
        }
        this.titleRef = React.createRef();
    };

    getContent = () => {
        const { id, name, title } = this.state;
        return {
            id,
            name,
            title,
            type: this.props.type
        };
    };

    onClickEditTitle = () => {
        this.setState({
            editTitle: true
        });
        setTimeout(() => {
            this.titleRef.current.focus();
        });
    };

    handleStateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
        this.setProperties({ [name]: value });
    };

    onFocusOutTitle = () => {
        this.setState({
            editTitle: false
        });
    };

    toggleIsRequired = (e) => {
        e.preventDefault();
        this.setState({
            isRequired: !this.state.isRequired
        });
        this.setProperties({ isRequired: !this.state.isRequired });
    };

    setProperties = (sendData) => {
        const { title, name, id, displaytype } = this.state;
        const { type } = this.props;
        const properties = {
            type,
            title: title,
            id: id,
            name: name,
            displaytype: displaytype,
            ...sendData
        };
        this.props.setPropertiesData(properties, this.props.location);
        this.setIsActive(true);
    }

    changeProperties = (formContent) => {
        const { title, placeHolder, name, id, displaytype } = formContent;
        this.setState({
            title: title,
            name: name,
            id: id,
            displaytype: displaytype,
        });
        this.props.setPropertiesData(formContent, this.props.location);
    };

    onClickDelete = () => {
        this.props.onClickDelete(this.props.location);
    };

    setIsActive = (isActive) => {
        this.setState({
            isActive
        });
    };

    render() {
        const { title, editTitle, displaytype } = this.state;
        return (
            <div className="col-4">
                <div className="form-group">
                    <label><strong>Table1</strong></label>
                    <div className="table">
                        <table width="100%">
                            <tr>
                                <td>
                                    <button className="btn btn-primary">Execute Form</button>
                                </td>
                                <td>
                                    <button className="btn btn-success">Execute Workflow</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button className="btn btn-primary">Redirect</button>
                                </td>
                                <td>
                                    <button className="btn btn-primary">Validate</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button className="btn btn-success">Complete Task</button>
                                </td>
                                <td>
                                    <button className="btn btn-danger">Reject Task</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="d-block text-center">
                        <button className="btn btn-primary">Reload</button>
                    </div>
                </div>
            </div>
        );
    }
}