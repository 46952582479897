import React, { Component } from 'react';

export class File extends Component {
    titleRef = null;
    constructor(props) {
        super(props);
        this.state = {
            title: "Question",
            editTitle: false,
            isRequired: false,
            name: 'question',
            id: 'question',
            notice: '',
            errorMessage: 'This field is required.',
            isActive: false,
            displaytype: 'horizontal',
            imgUrl: ''
        }
        this.titleRef = React.createRef();
    };

    getContent = () => {
        const { id, name, title, isRequired, errorMessage, notice, displaytype } = this.state;
        return {
            id,
            name,
            title,
            isRequired,
            errorMessage,
            notice,
            displaytype,
            type: this.props.type
        };
    };

    onClickEditTitle = () => {
        this.setState({
            editTitle: true
        });
        setTimeout(() => {
            this.titleRef.current.focus();
        });
    };

    handleStateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
        this.setProperties({ [name]: value });
    };

    onFocusOutTitle = () => {
        this.setState({
            editTitle: false
        });
    };

    setProperties = (sendData) => {
        const { title, isRequired, name, notice, errorMessage, id, displaytype } = this.state;
        const { type } = this.props;
        const properties = {
            type,
            id: id,
            title: title,
            name: name,
            notice: notice,
            isRequired: isRequired,
            errorMessage: errorMessage,
            displaytype: displaytype,
            ...sendData
        };
        this.props.setPropertiesData(properties, this.props.location);
        this.setIsActive(true);
    }

    changeProperties = (formContent) => {
        const { title, name, notice, errorMessage, isRequired, id, displaytype } = formContent;
        this.setState({
            title: title,
            id: id,
            name: name,
            notice: notice,
            errorMessage: errorMessage,
            isRequired: isRequired,
            displaytype: displaytype,
        });
        this.props.setPropertiesData(formContent, this.props.location);
    };

    setIsActive = (isActive) => {
        this.setState({
            isActive
        });
    };

    setImagUrl = (e) => {
        let fileName = e.target.files[0].name;
        var fileExtension = fileName.split('.').pop();
        if (fileExtension == 'png' || fileExtension == 'jpeg' || fileExtension == 'jpg' || fileExtension == 'gif') {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ imgUrl: e.target.result });
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            this.setState({ imgUrl: '' });
        }
    }

    render() {
        const { title, editTitle, displaytype, imgUrl } = this.state;
        return (
            <div className={displaytype == 'horizontal' ? "col-4 question-container-inner" : "col-12 question-container-inner"}>
                <div className={`pb-3 question-heading ${editTitle ? 'd-none' : 'd-block'}`}>
                    <strong className="d-inline-block">
                        {title}
                    </strong>
                    <div className="float-right">
                        <button onClick={this.onClickEditTitle} className="btn px-1 py-0">
                            <i className="fal fa-edit"></i>
                        </button>
                        <button onClick={() => this.setProperties({})} className="btn px-1 py-0">
                            <i class="fal fa-building"></i>
                        </button>
                    </div>
                </div>
                <div className={`pb-3 question-heading ${editTitle ? 'd-block' : 'd-none'}`}>
                    <input type="text" value={title} name="title" onChange={this.handleStateChange} onBlur={this.onFocusOutTitle} ref={this.titleRef} />
                </div>
                <div class="custom-file">
                    <input type="file" onChange={this.setImagUrl} class="custom-file-input file" id="chooseFile" />
                    <label class="custom-file-label" for="validatedCustomFile">No file choosen</label>
                </div>
                {imgUrl &&
                    <div className="d-block mt-3">
                        <img src={imgUrl} width="270px" height="270px" />
                    </div>
                }
            </div>
        );
    }
}