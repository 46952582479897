import React, { Component } from 'react';
import undoIcon from './../img/actions-icons/undo-icon.png'
import redoIcon from './../img/actions-icons/redo-icon.png'
import gridIcon from './../img/actions-icons/grid-icon.png'
import setTabIcon from './../img/actions-icons/set-tab-icon.png'
import snapshotsIcon from './../img/actions-icons/snapshots-icon.png'
import functionsIcon from './../img/actions-icons/functions-icon.png'
import stylesIcon from './../img/actions-icons/styles-icon.png'
import globalizationIcon from './../img/actions-icons/globalization-icon.png'

export class HTMLActions extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    };

    render() {
        return (
            <>
                <div className="actions-buttons">
                    <button className="btn"><img src={undoIcon} alt="" /> Undo</button>
                    <button className="btn"><img src={redoIcon} alt="" /> Redo</button>
                </div>
                <div className="actions-links">
                    <ul>
                        <li>
                            <a href="#"><img src={gridIcon} alt="" /> Grid On/Off</a>
                        </li>
                        <li>
                            <a href="#"><img src={gridIcon} alt="" /> Set Grid</a>
                        </li>
                        <li>
                            <a href="#"><img src={setTabIcon} alt="" /> Set Tab Indexes</a>
                        </li>
                        <li>
                            <a href="#"><img src={snapshotsIcon} alt="" /> Manage Snapshots</a>
                        </li>
                    </ul>
                </div>
                <div className="actions-links last">
                    <strong>Page</strong>
                    <ul>
                        <li>
                            <a href="#"><img src={functionsIcon} alt="" /> Functions</a>
                        </li>
                        <li>
                            <a href="#"><img src={stylesIcon} alt="" /> Styles</a>
                        </li>
                        <li>
                            <a href="#"><img src={globalizationIcon} alt="" /> Globalixation</a>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}