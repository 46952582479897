import inputTextIcon from './menu-icons/menu-icon1.png';
import textAreaIcon from './menu-icons/menu-icon1.png';
import inputNumberIcon from './menu-icons/menu-icon2.png';
import addressIcon from './menu-icons/menu-icon3.png';
import optionalQuestionsIcon from './menu-icons/menu-icon4.png';
import questionsIcon from './menu-icons/menu-icon5.png';
import buttonIcon from './menu-icons/menu-icon6.png';
import questionChoiceIcon from './menu-icons/menu-icon7.png';
import emailIcon from './menu-icons/menu-icon8.png';
import dateIcon from './menu-icons/menu-icon9.png';
import imageIcon from './menu-icons/menu-icon10.png';
import fileIcon from './menu-icons/menu-icon11.png';
import captchaIcon from './menu-icons/menu-icon12.png';
import executeFormIcon from './menu-icons/menu-icon13.png';
import generateBarcodeIcon from './menu-icons/menu-icon14.png';
import formApprovalIcon from './menu-icons/menu-icon15.png';
import hyperlinkIcon from './menu-icons/menu-icon16.png';
import iframeIcon from './menu-icons/menu-icon17.png';
import labelIcon from './menu-icons/menu-icon18.png';
import ratingsIcon from './menu-icons/menu-icon19.png';
import redirectFormIcon from './menu-icons/menu-icon20.png';
import reloadFormIcon from './menu-icons/menu-icon21.png';
import saveFormIcon from './menu-icons/menu-icon22.png';
import signatureIcon from './menu-icons/menu-icon23.png';
import subFormIcon from './menu-icons/menu-icon24.png';
import submitFormIcon from './menu-icons/menu-icon25.png';
import validateFormIcon from './menu-icons/menu-icon26.png';
import tableIcon from './menu-icons/menu-icon27.png';
import timeIcon from './menu-icons/menu-icon28.png';
import lineIcon from './menu-icons/menu-icon29.png';
import executeWorkflowIcon from './menu-icons/menu-icon30.png';
import tabWidgetIcon from './menu-icons/menu-icon31.png';

export const menuIcons = {
    inputTextIcon: inputTextIcon,
    textAreaIcon: textAreaIcon,
    inputNumberIcon: inputNumberIcon,
    addressIcon: addressIcon,
    optionalQuestionsIcon: optionalQuestionsIcon,
    questionsIcon: questionsIcon,
    buttonIcon: buttonIcon,
    questionChoiceIcon: questionChoiceIcon,
    emailIcon: emailIcon,
    dateIcon: dateIcon,
    imageIcon: imageIcon,
    fileIcon: fileIcon,
    captchaIcon: captchaIcon,
    executeFormIcon: executeFormIcon,
    generateBarcodeIcon: generateBarcodeIcon,
    formApprovalIcon: formApprovalIcon,
    hyperlinkIcon: hyperlinkIcon,
    iframeIcon: iframeIcon,
    labelIcon: labelIcon, 
    ratingsIcon: ratingsIcon,
    redirectFormIcon: redirectFormIcon,
    reloadFormIcon: reloadFormIcon,
    saveFormIcon: saveFormIcon,
    signatureIcon: signatureIcon,
    subFormIcon: subFormIcon,
    submitFormIcon: submitFormIcon,
    validateFormIcon: validateFormIcon,
    tableIcon: tableIcon,
    timeIcon: timeIcon,
    lineIcon: lineIcon,
    executeWorkflowIcon: executeWorkflowIcon,
    tabWidgetIcon: tabWidgetIcon
};