import React, { Component } from 'react';
import hyperlinkImg from '../img/hyperlink-img.png';

export class TabWidget extends Component {
    titleRef = null;
    constructor(props) {
        super(props);
        this.state = {
            title: "Question",
            editTitle: false,
            isRequired: false,
            name: 'question',
            id: 'question',
            notice: '',
            errorMessage: 'This field is required.',
            isActive: false,
            displaytype: 'horizontal'
        }
        this.titleRef = React.createRef();
    };

    getContent = () => {
        const { id, name, title, isRequired, errorMessage, notice, displaytype } = this.state;
        return {
            id,
            name,
            title,
            isRequired,
            errorMessage,
            notice,
            displaytype,
            type: this.props.type
        };
    };

    onClickEditTitle = () => {
        this.setState({
            editTitle: true
        });
        setTimeout(() => {
            this.titleRef.current.focus();
        });
    };

    handleStateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
        this.setProperties({ [name]: value });
    };

    onFocusOutTitle = () => {
        this.setState({
            editTitle: false
        });
    };

    toggleIsRequired = (e) => {
        e.preventDefault();
        this.setState({
            isRequired: !this.state.isRequired
        });
        this.setProperties({ isRequired: !this.state.isRequired });
    };

    setProperties = (sendData) => {
        const { title, isRequired, name, notice, errorMessage, id, displaytype } = this.state;
        const { type } = this.props;
        const properties = {
            type,
            id: id,
            title: title,
            name: name,
            notice: notice,
            isRequired: isRequired,
            errorMessage: errorMessage,
            displaytype: displaytype,
            ...sendData
        };
        this.props.setPropertiesData(properties, this.props.location);
        this.setIsActive(true);
    }

    changeProperties = (formContent) => {
        const { title, name, notice, errorMessage, isRequired, id, displaytype } = formContent;
        this.setState({
            title: title,
            id: id,
            name: name,
            notice: notice,
            errorMessage: errorMessage,
            isRequired: isRequired,
            displaytype: displaytype,
        });
        this.props.setPropertiesData(formContent, this.props.location);
    };

    onClickDelete = () => {
        this.props.onClickDelete(this.props.location);
    };

    setIsActive = (isActive) => {
        this.setState({
            isActive
        });
    };

    render() {
        const { title, editTitle, isRequired, notice, errorMessage, isActive, displaytype } = this.state;
        return (
            <div className="col-4">
                <div className="d-block">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={'nav-link active'}>Home</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link'}>Tab 2</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link'}>Tab 3</a>
                        </li>
                    </ul>
                    <div className="tab-content clearfix position-relative bg-white border border-top-0">
                        <div className="tab-pane p-3 active">
                            Home
                        </div>
                        <div className="tab-pane p-3">
                            Tab 2
                        </div>
                        <div className="tab-pane p-3">
                            Tab 3
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}