import React, { Component } from 'react';
import alignIcon1 from './../img/properties-icons/align-icon1.png'
import alignIcon2 from './../img/properties-icons/align-icon2.png'
import alignIcon3 from './../img/properties-icons/align-icon3.png'
import alignIcon4 from './../img/properties-icons/align-icon4.png'
import alignIcon5 from './../img/properties-icons/align-icon5.png'
import alignIcon6 from './../img/properties-icons/align-icon6.png'
export class HTMLProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    };

    render() {
        return (
            <>
                <div className="tab-editor">
                    <strong>Align</strong>
                    <div className="buttons">
                        <button className="btn"><img src={alignIcon1} alt="" /></button>
                        <button className="btn"><img src={alignIcon2} alt="" /></button>
                        <button className="btn"><img src={alignIcon3} alt="" /></button>
                        <button className="btn"><img src={alignIcon4} alt="" /></button>
                        <button className="btn"><img src={alignIcon5} alt="" /></button>
                        <button className="btn"><img src={alignIcon6} alt="" /></button>
                    </div>
                </div>
                <div className="tab-editor">
                    <strong>Transform</strong>
                    <div className="transform-input">
                        <div className="d-flex">
                            <div className="col-5 px-0">
                                <div className="form-group">
                                    <label htmlFor="W">W:</label>
                                    <input className="form-control" type="text" placeholder="70" />
                                </div>
                            </div>
                            <div className="col-5 px-0">
                                <div className="form-group drak">
                                    <label htmlFor="X">X:</label>
                                    <input className="form-control" type="text" placeholder="650" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="transform-input">
                        <div className="d-flex">
                            <div className="col-5 px-0">
                                <div className="form-group">
                                    <label htmlFor="H">H:</label>
                                    <input className="form-control" type="text" placeholder="20" />
                                </div>
                            </div>
                            <div className="col-5 px-0">
                                <div className="form-group drak">
                                    <label htmlFor="Y">Y:</label>
                                    <input className="form-control" type="text" placeholder="234" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-editor">
                    <strong>Text</strong>
                    <div className="select">
                        <select>
                            <option>Open Sans</option>
                            <option>Myriad Pro</option>
                            <option>Arial</option>
                            <option>Poppins</option>
                        </select>
                    </div>
                    <div className="select">
                        <div className="d-flex">
                            <div className="input-box">
                                <input type="text" className="form-control" placeholder="15" />
                            </div>
                            <div className="select-box">
                                <select>
                                    <option>Regular</option>
                                    <option>Italic</option>
                                    <option>Bold</option>
                                    <option>Bold Italic</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}