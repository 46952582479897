import React, { Component } from 'react';

export class Save extends Component {
    titleRef = null;
    constructor(props) {
        super(props);
        this.state = {
            title: "Title",
            editTitle: false,
            isRequired: false,
            name: 'question',
            id: 'question',
            notice: '',
            errorMessage: 'This field is required.',
            isActive: false,
            displaytype: 'horizontal'
        }
        this.titleRef = React.createRef();
    };

    getContent = () => {
        const { id, name, title, isRequired, errorMessage, notice, displaytype } = this.state;
        return {
            id,
            name,
            title,
            isRequired,
            errorMessage,
            notice,
            type: this.props.type
        };
    };

    onClickEditTitle = () => {
        this.setState({
            editTitle: true
        });
        setTimeout(() => {
            this.titleRef.current.focus();
        });
    };

    handleStateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
        this.setProperties({ [name]: value });
    };

    onFocusOutTitle = () => {
        this.setState({
            editTitle: false
        });
    };

    toggleIsRequired = (e) => {
        e.preventDefault();
        this.setState({
            isRequired: !this.state.isRequired
        });
        this.setProperties({ isRequired: !this.state.isRequired });
    };

    setProperties = (sendData) => {
        const { title, isRequired, name, notice, errorMessage, id, displaytype } = this.state;
        const { type } = this.props;
        const properties = {
            type,
            id: id,
            title: title,
            name: name,
            notice: notice,
            isRequired: isRequired,
            errorMessage: errorMessage,
            displaytype: displaytype,
            ...sendData
        };
        this.props.setPropertiesData(properties, this.props.location);
        this.setIsActive(true);
    }

    changeProperties = (formContent) => {
        const { title, name, notice, errorMessage, isRequired, id, displaytype } = formContent;
        this.setState({
            title: title,
            id: id,
            name: name,
            notice: notice,
            errorMessage: errorMessage,
            isRequired: isRequired,
            displaytype: displaytype,
        });
        this.props.setPropertiesData(formContent, this.props.location);
    };

    onClickDelete = () => {
        this.props.onClickDelete(this.props.location);
    };

    setIsActive = (isActive) => {
        this.setState({
            isActive
        });
    };

    render() {
        const { title, editTitle, isRequired, notice, errorMessage, isActive, displaytype } = this.state;
        return (
            <div className={displaytype == 'horizontal' ? "col-4 question-container-inner" : "col-12 question-container-inner"}>
                <div className={`pb-3 question-heading ${editTitle ? 'd-none' : 'd-block'}`}>
                    <strong className="d-inline-block">
                        {title}
                    </strong>
                    <div className="float-right">
                        <button onClick={this.onClickEditTitle} className="btn px-1 py-0">
                            <i className="fal fa-edit"></i>
                        </button>
                        <button onClick={() => this.setProperties({})} className="btn px-1 py-0">
                            <i class="fal fa-building"></i>
                        </button>
                    </div>
                </div>
                <div className={`pb-3 question-heading ${editTitle ? 'd-block' : 'd-none'}`}>
                    <input type="text" value={title} name="title" onChange={this.handleStateChange} onBlur={this.onFocusOutTitle} ref={this.titleRef} />
                </div>
                <div className="d-block">
                    <button className="btn btn-success">Save</button>
                </div>
            </div>
        );
    }
}