import React, { Component } from "react";
import "./css/workflow.css";
import {
  Textbox,
  TextArea,
  Checkbox,
  RadioGroup,
  SelectOption,
  File,
  Date,
  Label,
  Iframe,
  Captcha,
  Table,
  Signature,
  Hyperlink,
  TabWidget,
  InputNumber,
  Address,
  Email,
  Image,
  Ratings,
  GenerateBarcode,
  Time,
  Line,
  Redirect,
  Save,
} from "./components";
import { Scrollbars } from "react-custom-scrollbars";
import { menuIcons } from "./img";
import { RightPartProperties } from "./RightpartProperties";

export const componentType = {
  TEXT: "text",
  RADIO: "radio",
  CHECK_BOX: "checkbox",
  TEXT_AREA: "textarea",
  SELECTBOX: "select",
  FILE: "file",
  DATE: "date",
  LABEL: "label",
  CAPTCHA: "captcha",
  IFRAME: "iframe",
  TABLE: "table",
  SIGNATURE: "signature",
  HYPERLINK: "hyperlink",
  TABWIDGET: "tabwidget",
  INPUTNUMBER: "inputnumber",
  ADDRESS: "address",
  EMAIL: "email",
  IMAGE: "image",
  RATINGS: "ratings",
  GENERATEBARCODE: "generatebarcode",
  TIME: "time",
  LINE: "line",
  REDIRECT: "redirect",
  SAVE: "save",
};

export class WorkflowEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          label: "Tab 1",
          editTitle: false,
        },
      ],
      activeTab: 0,
      sidebarData: [
        {
          title: "Input Text",
          type: componentType.TEXT,
          value: "singleInput",
          iconImg: menuIcons.inputTextIcon,
        },
        {
          title: "Text Area",
          type: componentType.TEXT_AREA,
          value: "textDescription",
          iconImg: menuIcons.textAreaIcon,
        },
        {
          title: "Input Number",
          type: componentType.INPUTNUMBER,
          value: "inputNumber",
          iconImg: menuIcons.inputNumberIcon,
        },
        {
          title: "Address",
          type: componentType.ADDRESS,
          value: "address",
          iconImg: menuIcons.addressIcon,
        },
        {
          title: "Checkbox",
          type: componentType.CHECK_BOX,
          value: "optionalQuestions",
          iconImg: menuIcons.optionalQuestionsIcon,
        },
        {
          title: "Radio Button",
          type: componentType.RADIO,
          value: "questions",
          iconImg: menuIcons.questionsIcon,
        },
        {
          title: "Button",
          value: "button",
          iconImg: menuIcons.buttonIcon,
        },
        {
          title: "Dropdown",
          type: componentType.SELECTBOX,
          value: "questionChoice",
          iconImg: menuIcons.questionChoiceIcon,
        },
        {
          title: "Email",
          type: componentType.EMAIL,
          value: "email",
          iconImg: menuIcons.emailIcon,
        },
        {
          title: "Date",
          type: componentType.DATE,
          value: "date",
          iconImg: menuIcons.dateIcon,
        },
        {
          title: "Image",
          type: componentType.IMAGE,
          value: "image",
          iconImg: menuIcons.imageIcon,
        },
        {
          title: "File",
          type: componentType.FILE,
          value: "file",
          iconImg: menuIcons.fileIcon,
        },
        {
          title: "Captcha",
          value: "captcha",
          type: componentType.CAPTCHA,
          iconImg: menuIcons.captchaIcon,
        },
        {
          title: "Execute Form",
          value: "executeForm",
          iconImg: menuIcons.executeFormIcon,
        },
        {
          title: "Generate Barcode",
          type: componentType.GENERATEBARCODE,
          value: "generateBarcode",
          iconImg: menuIcons.generateBarcodeIcon,
        },
        {
          title: "Form Approval",
          value: "formApproval",
          iconImg: menuIcons.formApprovalIcon,
        },
        {
          title: "Hyperlink",
          value: "hyperlink",
          type: componentType.HYPERLINK,
          iconImg: menuIcons.hyperlinkIcon,
        },
        {
          title: "iFrame",
          value: "iframe",
          type: componentType.IFRAME,
          iconImg: menuIcons.iframeIcon,
        },
        {
          title: "Label",
          value: "label",
          type: componentType.LABEL,
          iconImg: menuIcons.labelIcon,
        },
        {
          title: "Ratings",
          type: componentType.RATINGS,
          value: "ratings",
          iconImg: menuIcons.ratingsIcon,
        },
        {
          title: "Redirect Form",
          type: componentType.REDIRECT,
          value: "redirectForm",
          iconImg: menuIcons.redirectFormIcon,
        },
        {
          title: "Reload Form",
          value: "reloadForm",
          iconImg: menuIcons.reloadFormIcon,
        },
        {
          title: "Save Form",
          type: componentType.SAVE,
          value: "saveForm",
          iconImg: menuIcons.saveFormIcon,
        },
        {
          title: "Signature",
          value: "signature",
          type: componentType.SIGNATURE,
          iconImg: menuIcons.signatureIcon,
        },
        {
          title: "Sub Form",
          value: "subForm",
          iconImg: menuIcons.subFormIcon,
        },
        {
          title: "Submit Form",
          value: "submitForm",
          iconImg: menuIcons.submitFormIcon,
        },
        {
          title: "Validate Form",
          value: "validateForm",
          iconImg: menuIcons.validateFormIcon,
        },
        {
          title: "Table",
          type: componentType.TABLE,
          value: "table",
          iconImg: menuIcons.tableIcon,
        },
        {
          title: "Time",
          type: componentType.TIME,
          value: "time",
          iconImg: menuIcons.timeIcon,
        },
        {
          title: "Line",
          type: componentType.LINE,
          value: "line",
          iconImg: menuIcons.lineIcon,
        },
        {
          title: "Execute Workflow",
          value: "executeWorkflow",
          iconImg: menuIcons.executeWorkflowIcon,
        },
        {
          title: "Tab Widget",
          value: "tabWidget",
          type: componentType.TABWIDGET,
          iconImg: menuIcons.tabWidgetIcon,
        },
      ],
      formDataContent: [],
      workflowEditor: [],
      showEditorPage: false,
      radiocount: 0,
      tabMetaData: [
        {
          title: "",
          subHeading: "",
          apiEndPoint: "",
        },
      ],
      activeLocation: {},
      rightActiveTab: null,
      formData: {},
    };
    this.formContentRefs = [];
    this.propertiesRef = React.createRef();
  }

  displaySideBar = () => {
    const { sidebarData } = this.state;
    const sidebarReturnData = [];
    for (let i = 0; i < sidebarData.length; i++) {
      let row = sidebarData[i];
      sidebarReturnData.push(
        <li key={`options-${i}`} onClick={(e) => this.displayFormContent(row)}>
          <div className="d-block">
            <span>
              <img src={row.iconImg} alt={row.title} width="28" height="28" />
            </span>
            <p>{row.title}</p>
          </div>
        </li>
      );
    }
    return sidebarReturnData;
  };

  displayFormContent = (fieldData) => {
    const {
      formDataContent,
      activeTab,
      activeLocation,
      radiocount,
    } = this.state;
    const { type, value } = fieldData;
    const tabContent = formDataContent[activeTab] || [];
    // const tabContentRef = this.formContentRefs[activeTab] || [];
    const ref = React.createRef();
    const index = this.formContentRefs.length;
    const location = {
      tab: activeTab,
      index: this.formContentRefs.length,
    };
    if (type === componentType.TEXT) {
      tabContent.push(
        <Textbox
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.CHECK_BOX) {
      tabContent.push(
        <Checkbox
          type={type}
          location={location}
          ref={ref}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.RADIO) {
      tabContent.push(
        <RadioGroup
          type={type}
          name={radiocount}
          location={location}
          ref={ref}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
      this.setState({
        radiocount: radiocount + 1,
      });
    } else if (type === componentType.TEXT_AREA) {
      tabContent.push(
        <TextArea
          type={type}
          location={location}
          ref={ref}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.SELECTBOX) {
      tabContent.push(
        <SelectOption
          type={type}
          location={location}
          ref={ref}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.FILE) {
      tabContent.push(
        <File
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.DATE) {
      tabContent.push(
        <Date
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.LABEL) {
      tabContent.push(
        <Label
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.IFRAME) {
      tabContent.push(
        <Iframe
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.CAPTCHA) {
      tabContent.push(
        <Captcha
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.TABLE) {
      tabContent.push(
        <Table
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.SIGNATURE) {
      tabContent.push(
        <Signature
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.HYPERLINK) {
      tabContent.push(
        <Hyperlink
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.TABWIDGET) {
      tabContent.push(
        <TabWidget
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.INPUTNUMBER) {
      tabContent.push(
        <InputNumber
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.ADDRESS) {
      tabContent.push(
        <Address
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.EMAIL) {
      tabContent.push(
        <Email
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.IMAGE) {
      tabContent.push(
        <Image
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.RATINGS) {
      tabContent.push(
        <Ratings
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.GENERATEBARCODE) {
      tabContent.push(
        <GenerateBarcode
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.TIME) {
      tabContent.push(
        <Time
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.LINE) {
      tabContent.push(
        <Line
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.REDIRECT) {
      tabContent.push(
        <Redirect
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    } else if (type === componentType.SAVE) {
      tabContent.push(
        <Save
          type={type}
          ref={ref}
          location={location}
          setPropertiesData={this.setProperty}
          key={`comp-${activeTab}-${index}`}
          onClickDelete={this.onClickDelete}
          activeLocation={activeLocation}
        />
      );
    }
    formDataContent[activeTab] = tabContent;
    this.setState({
      formDataContent,
    });
    this.formContentRefs.push(ref);
    this.propertiesRef.current.setRightActiveTab(0);
  };

  onChangeProperties = (formContent) => {
    const { activeLocation, activeTab } = this.state;
    const tab = this.formContentRefs[activeLocation.index];
    if (tab) {
      const ref = tab[activeLocation.index];
      if (ref) {
        ref.current.changeProperties(formContent);
      }
    }
    this.setState({
      formData: formContent,
    });
  };

  setProperty = (data, location) => {
    const { activeLocation } = this.state;
    let refArr = this.formContentRefs[activeLocation.index];
    if (refArr && refArr.length > 0) {
      const ref = refArr[activeLocation.index];
      ref && ref.current.setIsActive(false);
    }
    this.setState({
      showRightPart: true,
      activeLocation: location,
    });
    this.propertiesRef.current.setProperties(data);
  };

  onClickDelete = (location) => {
    const { formDataContent } = this.state;
    if (location) {
      const { index } = location;
      const refs = this.formContentRefs[index];
      let number = -1;
      for (let i = 0; i < refs.length; i++) {
        if (
          refs[i] &&
          refs[i].current &&
          refs[i].current.props.location.index === index
        ) {
          number = i;
          break;
        }
      }
      if (number !== -1) {
        formDataContent[index].splice(number, 1);
        this.formContentRefs[index].splice(number, 1);
        this.setState({
          formDataContent,
          activeLocation: {},
          showRightPart: false,
        });
      }
    }
  };

  deleteTab(index) {
    const { formDataContent } = this.state;
    if (index) {
      delete this.formContentRefs[index];
      delete formDataContent[index];
      this.setState({
        formDataContent,
        activeLocation: {},
        showRightPart: false,
      });
    }
  }

  displayTabs = () => {
    const { tabs, activeTab } = this.state;
    const retData = [];
    for (let i = 0; i < tabs.length; i++) {
      let tab = tabs[i];
      retData.push(
        <li key={`tab-${i}`} className={`nav-item `}>
          <a
            className={i === activeTab ? "nav-link active" : "nav-link"}
            onClick={(e) => this.navigateTab(i)}
            href={void 0}
          >
            {!tab.editTitle && tab.label}&nbsp;
            {tab.editTitle && (
              <input
                type="text"
                className="form-control tab-edit"
                value={tab.label}
                name="title"
                onChange={(e) => this.handleStateChange(e, i)}
                onBlur={() => this.onFocusOutTitle(i)}
              />
            )}
            <i className="fa fa-edit edit" onClick={(e) => this.editTab(i)}></i>
            <i className="fa fa-trash" onClick={(e) => this.deleteTab(i)}></i>
          </a>
        </li>
      );
    }
    return retData;
  };

  handleStateChange = (e, index) => {
    const { tabs } = this.state;
    const { name, value } = e.target;
    for (let i = 0; i < tabs.length; i++) {
      if (i === index) {
        tabs[i].label = value;
      }
    }
    this.setState({
      tabs,
    });
  };

  onFocusOutTitle = (index) => {
    const { tabs } = this.state;
    for (let i = 0; i < tabs.length; i++) {
      if (i === index) {
        tabs[i].editTitle = false;
      }
    }
    this.setState({
      tabs,
    });
  };

  editTab = (index) => {
    const { tabs } = this.state;
    for (let i = 0; i < tabs.length; i++) {
      if (i === index) {
        tabs[i].editTitle = true;
      }
    }
    this.setState({
      tabs,
    });
  };

  navigateTab(index) {
    this.setState({
      activeTab: index,
    });
  }

  handleTabMetaDataChange = (i, e) => {
    const { tabMetaData } = this.state;
    if (tabMetaData[i]) {
      const { name, value } = e.target;
      tabMetaData[i][name] = value;
      this.setState({
        tabMetaData,
      });
    }
  };

  displayTabsContent = () => {
    const { tabs, formDataContent, activeTab, tabMetaData } = this.state;
    const retData = [];
    for (let i = 0; i < tabs.length; i++) {
      retData.push(
        <div className="row">
          {formDataContent[i] && formDataContent[i].map((value) => value)}
        </div>
      );
    }
    return retData;
  };

  clearServeyForm = () => {
    this.setState({
      formDataContent: {},
      activeTab: 0,
      tabs: [
        {
          label: "Tab 1",
        },
      ],
      activeLocation: {},
      showRightPart: false,
    });
    this.formContentRefs = [];
  };

  setRightActiveTab = (rightActiveTab) => {
    this.setState({
      rightActiveTab,
    });
  };

  createPreview = () => {
    const { showEditorPage } = this.state;
    let content = [];
    for (let j = 0; j < this.formContentRefs.length; j++) {
      let row = this.formContentRefs[j];
      if (row) {
        content.push(row.current.getContent());
      }
    }
    this.setState({
      properties: JSON.stringify(content),
      showEditorPage: !showEditorPage,
    });
  };

  showCreateWorkflow = () => {
    const { showEditorPage } = this.state;
    this.setState({
      showEditorPage: !showEditorPage,
    });
  };

  render() {
    const { showEditorPage, properties } = this.state;
    return (
      <div className="d-block editor-container">
        <div className="d-block form-definition-heading">
          <h2>Form Definition</h2>
        </div>
        <div className="left-side">
          <Scrollbars style={{ maxHeight: "100%" }}>
            <ul>{this.displaySideBar()}</ul>
          </Scrollbars>
        </div>
        <div className="center-container">
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <div className="d-block center-heading-box">
              <div className="d-flex">
                <div className="col-6 px-0">
                  <h3>Form Name</h3>
                </div>
                <div className="col-6 px-0">
                  <div className="d-block text-right">
                    <button
                      className="btn btn-primary preview-btn"
                      onClick={this.createPreview}
                    >
                      Preview
                    </button>
                    <button className="btn btn-primary save-btn">SAVE</button>
                    <button
                      className="btn btn-primary clear-btn"
                      onClick={this.clearServeyForm}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "calc(100% - 70px)", display: "flex" }}>
            <Scrollbars style={{ maxHeight: "100%" }}>
              <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div className={`${showEditorPage ? "d-none" : "d-block"}`}>
                  {this.displayTabsContent()}
                </div>
                {showEditorPage && (
                  <div className="d-block pb-4">
                    <button
                      className="btn btn-primary mr-2 mb-3"
                      onClick={this.showCreateWorkflow}
                    >
                      <i className="fa fa-arrow-left"></i> Back
                    </button>
                    <div className="tab-content clearfix position-relative bg-white border border-top-0 p-3">
                      <textarea
                        className="form-control"
                        rows={5}
                        value={properties}
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
        <RightPartProperties
          ref={this.propertiesRef}
          onChangeContent={this.onChangeProperties}
        />
      </div>
    );
  }
}
